import React, { useState } from 'react';

import { FilterInput } from "./FilterInput.js";

import './filterContainer.scss';

export function FilterContainer(props) {
  const [inputVal, setInputVal] = useState("");

  function addFilter(filter) {
    setInputVal("");

    props.addFilter(filter);
  }

  return (
    <div className="filter_container">
      {
        props.filters.map(
          item => <FilterItem
                    key={item.name}
                    item={item}
                    removeFilter={props.removeFilter}
                  />
        )
      }
      <FilterInput
        filterOptions={props.filterOptions}
        addFilter={addFilter}
        inputVal={inputVal}
        setInputVal={setInputVal}
        currentFilters={props.filters}
        getFilterByName={props.getFilterByName}
        removeFilter={props.removeFilter}
      />
    </div>
  );
}

function FilterItem(props) {
  return <span className={`item filter-${props.item.type}`}>{props.item.name} <span className="remove" onClick={() => props.removeFilter(props.item)}>×</span></span>
}

