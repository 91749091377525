import React from 'react';

import './filterOptions.scss';

export function FilterOptions(props) {
  if (!props.validOptions.length) {
    return "";
  }

  return <div className="filterOptions">{
    props.validOptions.map(
      (opt, idx) =>
        <FilterOption
          filter={opt}
          addFilter={props.addFilter}
          key={opt.name}
          className={`filter-${opt.type}`}
          selected={idx === props.highlight }
        />
    )
  }</div>
}

function FilterOption(props) {
  return <div className="filterOption" onClick={() => props.addFilter(props.filter)} data-selected={props.selected}>
    {props.filter.name}
  </div>
}
