const entries = [
  {
    title: "Collect for the First Sunday in Advent (BCP)",
    content: "Almighty God, give us grace that we may cast away the works of darkness, and put upon us the armour of light, now in the time of this mortal life, in which thy Son Jesus Christ came to visit us in great humility; that in the last day, when he shall come again in his glorious majesty to judge both the quick and the dead, we may rise to the life immortal, through him who liveth and reigneth with thee and the Holy Ghost, now and ever. Amen.",
    tags: ["First Sunday in Advent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday in Advent (BCP)",
    content: "Blessed Lord, who hast caused all holy Scriptures to be written for our learning; Grant that we may in such wise hear them, read, mark, learn, and inwardly digest them, that by patience and comfort of thy holy Word, we may embrace, and ever hold fast, the blessed hope of everlasting life, which thou hast given us in our Saviour Jesus Christ. Amen.",
    tags: ["Second Sunday in Advent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Third Sunday in Advent (BCP)",
    content: "O Lord Jesus Christ, who at thy first coming didst send thy messenger to prepare thy way before thee; Grant that the ministers and stewards of thy mysteries may likewise so prepare and make ready thy way, by turning the hearts of the disobedient to the wisdom of the just, that at thy second coming to judge the world we may be found an acceptable people in thy sight, who livest and reignest with the Father and the Holy Spirit ever, one God, world without end. Amen.",
    tags: ["Third Sunday in Advent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fourth Sunday in Advent (BCP)",
    content: "O Lord, raise up, we pray thee, thy power, and come among us, and with great might succour us; that whereas, through our sins and wickedness, we are sore let and hindered in running the race that is set before us, thy bountiful grace and mercy may speedily help and 'deliver us; through the satisfaction of thy Son our Lord*, to whom, with thee and the Holy Ghost, be honour and glory, world with out end. Amen.",
    tags: ["Fourth Sunday in Advent", "BCP", "Collect"]
  },
  {
    title: "Collect for Christmas Day (BCP)",
    content: "Almighty God, who hast given us thy only begotten Son to take our nature upon him, and as at this time to be born of a pure virgin; Grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy holy Spirit; through the same our Lord Jesus Christ, who liveth and reigneth with thee and the same Spirit ever, one God, world without end. Amen.",
    tags: ["Christmas Day", "BCP", "Collect"]
  },
  {
    title: "Collect for the First Sunday after Christmas Day (BCP)",
    content: "Almighty God, who hast given us thy only-begotten Son to take our nature upon him, and as at this time to be born of a pure virgin: Grant that we being regenerate, and made thy children by adoption and grace. may daily be renewed by thy Holy Spirit; through the same our Lord Jesus Christ, who liveth and reigneth with thee and the same Spirit ever, one God, world without end. Amen.",
    tags: ["First Sunday after Christmas Day", "BCP", "Collect"]
  },
  {
    title: "Collect for the Circumcision of Christ (BCP)",
    content: "Almighty God, who madest thy blessed Son to be circumcised, and obedient to the law for man; Grant us the true circumcision of the Spirit; that, our hearts, and all our members, being mortified from all worldly and carnal lusts, we may in all things obey thy blessed will; through time same thy Son Jesus Christ our Lord. Amen.",
    tags: ["Circumcision of Christ", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday after Christmas Day (BCP)",
    content: "Almighty God, who hast poured upon us the new light of thine incarnate Word; Grant that the same light enkindled in our hearts may shine forth in our lives; through Jesus Christ our Lord. Amen. ",
    tags: ["Second Sunday after Christmas Day", "BCP", "Collect"]
  },
  {
    title: "Collect for the Epiphany (BCP)",
    content: "O God, who by the leading of a star didst manifest thy only-begotten Son to the Gentiles; Mercifully grant that we, who know thee now by faith, may after this life have the fruition of thy glorious Godhead; through Jesus Christ our Lord. Amen.",
    tags: ["The Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the First Sunday after the Epiphany (BCP)",
    content: "O Lord, we beseech thee mercifully to receive the prayers of thy people who call upon thee; and grant that they may both perceive and know what things they ought to do, and also may have grace and power faithfully to fulfil the same; through Jesus Christ our Lord. Amen.",
    tags: ["First Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday after the Epiphany (BCP)",
    content: "Almighty and everlasting God, who dost govern all things in heaven and earth; Mercifully hear the supplications of thy people, and grant us thy peace all the days of our life; through Jesus Christ our Lord. Amen.",
    tags: ["Second Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the Third Sunday after the Epiphany (BCP)",
    content: "Almighty and everlasting God, mercifully look upon our infirmities, and in all our dangers and necessities stretch forth thy right hand to help and defend us; through Jesus Christ our Lord. Amen.",
    tags: ["Third Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fourth Sunday after the Epiphany (BCP)",
    content: "O God, who knowest us to be set in the midst of so many and great dangers, that by reason of the frailty of our nature we cannot always stand upright; Grant to us such strength and protection, as may support us in all dangers, and carry us through all temptations; through Jesus Christ our Lord. Amen.",
    tags: ["Fourth Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fifth Sunday after the Epiphany (BCP)",
    content: "O Lord, we beseech thee to keep thy Church and household continually in thy true religion; that they who do lean only upon the hope of thy heavenly grace may evermore be defended by thy mighty power; through Jesus Christ our Lord. Amen.",
    tags: ["Fifth Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for the Sixth Sunday after the Epiphany (BCP)",
    content: "O God, whose blessed Son was manifested that he might destroy the works of the devil, and make us the sons of God, and heirs of eternal life; Grant us, we beseech thee, that, having this hope, we may purify ourselves, even as he is pure; that, when he shall appear again with power and great glory, we may be made like unto him in his eternal and glorious kingdom; where with thee, O Father, and thee, O Holy Ghost, he liveth and reigneth ever, one God, world without end. Amen.",
    tags: ["Sixth Sunday after the Epiphany", "BCP", "Collect"]
  },
  {
    title: "Collect for Septuagesima (BCP)",
    content: "O Lord, we beseech thee favourably to hear the prayers of thy people; that we, who are justly punished for our offences, may be mercifully delivered by thy goodness, for the glory of thy Name; through Jesus Christ our Saviour, who liveth and, reigneth with thee and the Holy Ghost ever, one God, world without end. Amen.",
    tags: ["Septuagesima", "BCP", "Collect"]
  },
  {
    title: "Collect for Sexagesima (BCP)",
    content: "O Lord God, who seest that we put not our trust in any thing that we do; Mercifully grant that by thy power we may be defended against all adversity; through Jesus Christ our Lord. Amen.",
    tags: ["Sexagesima", "BCP", "Collect"]
  },
  {
    title: "Collect for Quinquagesima (BCP)",
    content: "O Lord, who hast taught us that all our doings without charity are nothing worth; Send thy Holy Ghost, and pour into our hearts that most excellent gift of charity, the very bond of peace and of all virtues, without which whosoever liveth is counted dead before thee. Grant this for thine only Son Jesus Christ's sake. Amen.",
    tags: ["Quinquagesima", "BCP", "Collect"]
  },
  {
    title: "Collect for Ash Wednesday (BCP)",
    content: "Almighty and everlasting God, who hatest nothing that thou hast made, and dost forgive the sins of all those who are penitent; Create and make in us new and contrite hearts, that we,worthily lamenting our sins and acknowledging our wretchedness. may obtain of thee, the God of all mercy, perfect remission and forgiveness; through Jesus Christ our Lord. Amen.",
    tags: ["Ash Wednesday", "BCP", "Collect"]
  },
  {
    title: "Collect for the First Sunday in Lent (BCP)",
    content: "O Lord, who for our sake didst fast forty days and forty nights; Give us grace to use such abstinence, that, our flesh being subdued to the Spirit, we may ever obey thy godly motions in righteousness, and true holiness, to thy honour and glory, who livest and reignest with the Father and the Holy Ghost, one God, world without end. Amen.",
    tags: ["First Sunday in Lent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday in Lent (BCP)",
    content: "Almighty God, who seest that we have no power of ourselves to help ourselves; Keep us both outwardly in our bodies, and inwardly in our souls; that we may be defended from all adversities which may happen to the body, and from all evil thoughts which may assault and hurt the soul; through Jesus Christ our Lord. Amen.",
    tags: ["Second Sunday in Lent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Third Sunday in Lent (BCP)",
    content: "We beseech thee, Almighty God, look upon the hearty desires of thy humble servants, and stretch forth the right hand of thy Majesty, to be our defence against all our enemies; through Jesus Christ our Lord. Amen.",
    tags: ["Third Sunday in Lent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fourth Sunday in Lent (BCP)",
    content: "Grant, we beseech thee, Almighty God, that we, who for our evil deeds do worthily deserve to be punished, by the comfort of thy grace may mercifully be relieved; through our Lord and Saviour Jesus Christ. Amen.",
    tags: ["Fourth Sunday in Lent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fifth Sunday in Lent (BCP)",
    content: "We beseech thee, Almighty God, mercifully to look upon thy people; that by thy great goodness they may be governed and preserved evermore, both in body and soul; through Jesus Christ our Lord. Amen.",
    tags: ["Fifth Sunday in Lent", "BCP", "Collect"]
  },
  {
    title: "Collect for the Sunday Next before Easter (BCP)",
    content: "Almighty and everlasting God, who, of thy tender love towards mankind, hast sent thy Son, our Saviour Jesus Christ, to take upon him our flesh, and to suffer death upon the cross, that all mankind should follow the example of his great humility: Mercifully grant, that we may both follow the example of his patience, and also be made partakers of his resurrection; through the same Jesus Christ our Lord. Amen.",
    tags: ["Sunday Next before Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for Good Friday (BCP)",
    content: `Almighty God, we beseech thee graciously to behold this thy family, for which our Lord Jesus Christ was contented to be betrayed, and given up into the hands of wicked men, and to suffer death upon the cross, who now liveth and reigneth with thee and the Holy Ghost, ever one God, world without end. Amen.

Almighty and everlasting God, by whose Spirit the whole body of the Church is governed and sanctified: Receive our supplications and prayers, which we offer before thee for all estates of men in thy holy Church, that every member of the same, in his vocation and ministry, may truly and godly serve thee; through our Lord and Saviour Jesus Christ. Amen.

O merciful God, who hast made all men, and hatest nothing that thou hast made, nor wouldest the death of a sinner, but rather that he should be converted and live: Have mercy upon all Jews, Turks, Infidels, and Hereticks, and take from them all ignorance, hardness of heart, and contempt of thy word; and so fetch them home, blessed Lord, to thy flock, that they may be saved among the remnant of the true Israelites, and be made one fold under one shepherd, Jesus Christ our Lord, who liveth and reigneth with thee and the Holy Spirit, one God, world without end. Amen.`,
    tags: ["Good Friday", "BCP", "Collect"]
  },
  {
    title: "Collect for Easter Even (BCP)",
    content: "Grant, O Lord, that as we are baptized into the death of thy blessed Son our Saviour Jesus Christ, so by continual mortifying our corrupt affections we may be buried with him; and that, through the grave, and gate of death, we may pass to our joyful resurrection; for his merits, who died, and was buried, and rose again for us, thy Son Jesus Christ our Lord. Amen.",
    tags: ["Easter Even", "BCP", "Collect"]
  },
  {
    title: "Collect for Easter Day (BCP)",
    content: "Almighty God, who through thine only-begotten Son Jesus Christ hast overcome death, and opened unto us the gate of everlasting life: We humbly beseech thee, that as by thy special grace preventing us thou dost put into our minds good desires, so by thy continual help we may bring the same to good effect; through Jesus Christ our Lord, who liveth and reigneth with thee and the Holy Ghost, ever one God, world without end. Amen.",
    tags: ["Easter Day", "BCP", "Collect"]
  },
  {
    title: "Collect for the First Sunday after Easter (BCP)",
    content: "Almighty Father, who hast given thine only Son to die for our sins, and to rise again for our justification: Grant us so to put away the leaven of malice and wickedness, that we may alway serve thee in pureness of living and truth; through the merits of the same thy Son Jesus Christ our Lord. Amen.",
    tags: ["First Sunday after Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday after Easter (BCP)",
    content: "Almighty God, who hast given thine only Son to be unto us both a sacrifice for sin, and also an ensample of godly life: Give us grace that we may always most thankfully receive that his inestimable benefit, and also daily endeavour ourselves to follow the blessed steps of his most holy life; through the same Jesus Christ our Lord. Amen.",
    tags: ["Second Sunday after Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for the Third Sunday after Easter (BCP)",
    content: "Almighty God, who shewest to them that be in error the light of thy truth, to the intent that they may return into the way of righteousness: Grant unto all them that are admitted into the fellowship of Christ's religion, that they may eschew those things that are contrary to their profession, and follow all such things as are agreeable to the same; through our Lord Jesus Christ. Amen.",
    tags: ["Third Sunday after Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fourth Sunday after Easter (BCP)",
    content: "O Almighty God, who alone canst order the unruly wills and affections of sinful men; Grant unto thy people, that they may love the thing which thou commandest, and desire that which thou dost promise; that so, among the sundry and manifold changes of the world, our hearts may surely there be fixed, where true joys are to be found; through Jesus Christ our Lord. Amen.",
    tags: ["Fourth Sunday after Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for the Fifth Sunday after Easter (BCP)",
    content: "O Lord, from whom all good things do come: Grant to us thy humble servants, that by thy holy inspiration we may think those things that be good, and by thy merciful guiding may perform the same; through our Lord Jesus Christ. Amen.",
    tags: ["Fifth Sunday after Easter", "BCP", "Collect"]
  },
  {
    title: "Collect for the Ascension Day (BCP)",
    content: "Grant, we beseech thee, Almighty God, that like as we do believe thy only-begotten Son our Lord Jesus Christ to have ascended into the heavens; so we may also in heart and mind thither ascend, and with him continually dwell, who liveth and reigneth with thee and the Holy Ghost, one God, world without end. Amen.",
    tags: ["Ascension Day", "BCP", "Collect"]
  },
  {
    title: "Collect for the Sunday after Ascension Day (BCP)",
    content: "O God the King of glory, who hast exalted thine only Son Jesus Christ with great triumph unto thy kingdom in heaven: We beseech thee, leave us not comfortless; but send to us thine Holy Ghost to comfort us, and exalt us unto the same place whither our Saviour Christ is gone before, who liveth and reigneth with thee and the Holy Ghost, one God, world without end. Amen.",
    tags: ["Sunday after Ascension Day", "BCP", "Collect"]
  },
  {
    title: "Collect for Whitsunday (BCP)",
    content: "God, who as at this time didst teach the hearts of thy faithful people, by the sending to them the light of thy Holy Spirit; Grant us by the same Spirit to have a right judgement in all things, and evermore to rejoice in his holy comfort; through the merits of Christ Jesus our Saviour, who liveth and reigneth with thee, in the unity of the same Spirit, one God, world without end. Amen.",
    tags: ["Whitsunday", "BCP", "Collect"]
  },
  {
    title: "Collect for Trinity Sunday (BCP)",
    content: "Almighty and everlasting God, who hast given unto us thy servants grace, by the confession of a true faith to acknowledge the glory of the eternal Trinity, and in the power of the Divine Majesty to worship the Unity: We beseech thee, that thou wouldest keep us stedfast in this faith, and evermore defend us from all adversities, who livest and reignest, one God, world without end. Amen.",
    tags: ["Trinity Sunday", "BCP", "Collect"]
  },
  {
    title: "Collect for the First Sunday after Trinity (BCP)",
    content: "O God, the strength of all them that put their trust in thee, mercifully accept our prayers; and because through the weakness of our mortal nature we can do no good thing without thee, grant us the help of thy grace, that in keeping of thy commandments we may please thee, both in will and deed; through Jesus Christ our Lord. Amen.",
    tags: ["First Sunday after Trinity", "BCP", "Collect"]
  },
  {
    title: "Collect for the Second Sunday after Trinity (BCP)",
    content: "O Lord, who never failest to help and govern them who thou dost bring up in thy stedfast fear and love: Keep us, we beseech thee, under the protection of thy good providence, and make us to have a perpetual fear and love of thy holy Name; through Jesus Christ our Lord. Amen.",
    tags: ["Second Sunday after Trinity", "BCP", "Collect"]
  },
  {
    title: 'Collect for the Third Sunday after Trinity',
    content: 'O Lord, we beseech thee mercifully to hear us; and grant that we, to whom thou hast given an hearty desire to pray, may by thy mighty aid be defended and comforted in all dangers and adversities; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Third Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Fourth Sunday after Trinity',
    content: "O God, the protector of all that trust in thee, without whom nothing is strong, nothing is holy: Increase and multiply upon us thy mercy; that, thou being our ruler and guide, we may so pass through things temporal, that we finally lose not the things eternal: Grant this, O heavenly Father, for Jesus Christ's sake our Lord. Amen.",
    tags: [ 'BCP', 'Collect', 'Fourth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Fifth Sunday after Trinity',
    content: 'Grant, O Lord, we beseech thee, that the course of this world may be so peaceably ordered by thy governance, that thy Church may joyfully serve thee in all godly quietness; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Fifth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Sixth Sunday after Trinity',
    content: "O God, who hast prepared for them that love thee such good things as pass man's understanding: Pour into our hearts such love toward thee, that we, loving thee above all things, may obtain thy promises, which exceed all that we can desire; through Jesus Christ our Lord. Amen.",
    tags: [ 'BCP', 'Collect', 'Sixth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Seventh Sunday after Trinity',
    content: 'Lord of all power and might, who art the author and giver of all good things: Graft in our hearts the love of thy name, increase in us true religion, nourish us with all goodness, and of thy great mercy keep us in the same; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Seventh Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Eighth Sunday after Trinity',
    content: 'O God, whose never-failing providence ordereth all things both in heaven and earth; We humbly beseech thee to put away from us all hurtful things, and to give us those things which be profitable for us; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Eighth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Ninth Sunday after Trinity',
    content: 'Grant to us, Lord, we beseech thee, the spirit to think and do always such things as be rightful; that we, who cannot do any thing that is good without thee, may by thee be enabled to live according to thy will; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Ninth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Tenth Sunday after Trinity',
    content: 'Let thy merciful ears, O Lord, be open to the prayers of thy humble servants; and that they may obtain their petitions make them to ask such things as shall please thee; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Tenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Eleventh Sunday after Trinity',
    content: 'O God, who declarest thy almighty power most chiefly in shewing mercy and pity: Mercifully grant unto us such a measure of thy grace, that we, running the way of thy commandments, may obtain thy gracious promises, and be made partakers of thy heavenly treasure; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Eleventh Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Twelfth Sunday after Trinity',
    content: 'Almighty and everlasting God, who art always more ready to hear than we are to pray, and art wont to give more than either we desire or deserve: Pour down upon us the abundance of thy mercy; forgiving us those things whereof our conscience is afraid, and giving us those good things which we are not worthy to ask, but through the merits and mediation of Jesus Christ, thy Son, our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Twelfth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Thirteenth Sunday after Trinity',
    content: 'Almighty and merciful God, of whose only gift it cometh that thy faithful people do unto thee true and laudable service: Grant, we beseech thee, that we may so faithfully serve thee in this life, that we fail not finally to attain thy heavenly promises; through the merits of Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Thirteenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Fourteenth Sunday after Trinity',
    content: 'Almighty and everlasting God, give unto us the increase of faith, hope, and charity; and, that we may obtain that which thou dost promise, make us to love that which thou dost command; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Fourteenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Fifteenth Sunday after Trinity',
    content: 'Keep, we beseech thee, O Lord, thy Church with thy perpetual mercy; and, because the frailty of man without thee cannot but fall, keep us ever by thy help from all things hurtful, and lead us to all things profitable to our salvation; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Fifteenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Sixteenth Sunday after Trinity',
    content: 'O Lord, we beseech thee, let thy continual pity cleanse and defend thy Church; and, because it cannot continue in safety without thy succour, preserve it evermore by thy help and goodness; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Sixteenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Seventeenth Sunday after Trinity',
    content: 'Lord, we pray thee that thy grace may always prevent and follow us, and make us continually to be given to all good works; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Seventeenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Eighteenth Sunday after Trinity',
    content: 'Lord, we beseech thee, grant thy people grace to withstand the temptations of the world, the flesh, and the devil, and with pure hearts and minds to follow thee the only God; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Eighteenth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Nineteenth Sunday after Trinity',
    content: 'O God, forasmuch as without thee we are not able to please thee; Mercifully grant, that thy Holy Spirit may in all things direct and rule our hearts; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Nineteenth Sunday after Trinity' ]
  },
  {
    title: "Collect for the Twentieth Sunday after Trinity (BCP)",
    content: "O Almighty and most merciful God, of thy bountiful goodness keep us, we beseech thee, from all things that may hurt us; that we, being ready both in body and soul, may cheerfully accomplish those things that thou wouldest have done; through Jesus Christ our Lord. Amen.",
    tags: ["Twentieth Sunday after Trinity", "BCP", "Collect"]
  },
  {
    title: "Collect for the Twenty-First Sunday after Trinity (BCP)",
    content: "Grant, we beseech thee, merciful Lord, to thy faithful people pardon and peace; that they may be cleansed from all their sins, and serve thee with a quiet mind; through Jesus Christ our Lord. Amen.",
    tags: ["Twenty-First Sunday after Trinity", "BCP", "Collect"]
  },
  {
    title: "Collect for the Twenty-Second Sunday after Trinity (BCP)",
    content: "Lord, we beseech thee to keep thy household the Church in continual godliness; that through thy protection it may be free from all adversities, and devoutly given to serve thee in good works, to the glory of thy name; through Jesus Christ our Lord. Amen.",
    tags: ["Twenty-Second Sunday after Trinity", "BCP", "Collect"]
  },
  {
    title: "Collect for the Twenty-Third Sunday after Trinity (BCP)",
    content: "O God, our refuge and strength, who art the author of all godliness: Be ready, we beseech thee, to hear the devout prayers of thy Church; and grant that those things which we ask faithfully we may obtain effectually; through Jesus Christ our Lord. Amen.",
    tags: ["Twenty-Third Sunday after Trinity", "BCP", "Collect"]
  },
    {
    title: 'Collect for the Twenty-Fourth Sunday after Trinity (BCP)',
    content: "O Lord, we beseech thee, absolve thy people from their offences; that through thy bountiful goodness we may all be delivered from the bands of those sins, which by our frailty we have committed: Grant this, O heavenly Father, for Jesus Christ's sake, our blessed Lord and Saviour. Amen.",
    tags: [ 'BCP', 'Collect', 'Twenty-Fourth Sunday after Trinity' ]
  },
  {
    title: 'Collect for the Twenty-Fifth Sunday after Trinity (BCP)',
    content: 'Stir up, we beseech thee, O Lord, the wills of thy faithful people; that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Twenty-Fifth Sunday after Trinity' ]
  },
    {
    title: "Collect for Saint Andrew's Day (BCP)",
    content: 'Almighty God, who didst give such grace unto thy holy Apostle Saint Andrew, that he readily obeyed the calling of thy Son Jesus Christ, and followed him without delay: Grant unto us all, that we, being called by thy holy word, may forthwith give up ourselves obediently to fulfil thy holy commandments; through the same Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Andrew' ]
  },
  {
    title: 'Collect for Saint Thomas the Apostle (BCP)',
    content: "Almighty and everliving God, who for the more confirmation of the faith didst suffer thy holy Apostle Thomas to be doubtful in thy Son's resurrection: Grant us so perfectly, and without all doubt, to believe in thy Son Jesus Christ, that our faith in thy sight may never be reproved. Hear us, O Lord, through the same Jesus Christ, to whom, with thee and the Holy Ghost, be all honour and glory, now and for evermore. Amen.",
    tags: [ 'BCP', 'Collect', 'Saint Thomas the Apostle' ]
  },
  {
    title: 'Collect for the Conversion of Saint Paul (BCP)',
    content: 'O God, who, through the preaching of the blessed Apostle Saint Paul, hast caused the light of the Gospel to shine throughout the world: Grant, we beseech thee, that we, having his wonderful conversion in remembrance, may shew forth our thankfulness unto thee for the same, by following the holy doctrine which he taught; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Conversion of Saint Paul' ]
  },
  {
    title: 'Collect for the Purification of Saint Mary the Virgin (BCP)',
    content: 'Almighty and everliving God, we humbly beseech thy Majesty, that, as thy only-begotten Son was this day presented in the temple in substance of our flesh, so we may be presented unto thee with pure and clean hearts, by the same thy Son Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Purification of Saint Mary the Virgin' ]
  },
  {
    title: "Collect for Saint Matthias's Day (BCP)",
    content: 'O Almighty God, who into the place of the traitor Judas didst choose thy faithful servant Matthias to be of the number of the twelve Apostles: Grant that thy Church, being alway preserved from false Apostles, may be ordered and guided by faithful and true pastors; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Matthias' ]
  },
  {
    title: 'Collect for the Annunciation of the Blessed Virgin Mary (BCP)',
    content: 'We beseech thee, O Lord, pour thy grace into our hearts; that, as we have known the incarnation of thy Son Jesus Christ by the message of an angel, so by his cross and passion we may be brought unto the glory of his resurrection; through the same Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Annunciation of the Blessed Virgin Mary' ]
  },
  {
    title: "Collect for Saint Mark's Day (BCP)",
    content: 'O Almighty God, who hast instructed thy holy Church with the heavenly doctrine of thy Evangelist Saint Mark: Give us grace, that, being not like children carried away with every blast of vain doctrine, we may be established in the truth of thy holy Gospel; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Mark' ]
  },
  {
    title: "Collect for Saint Philip and Saint James's Day (BCP)",
    content: 'O Almighty God, whom truly to know is everlasting life: Grant us perfectly to know thy Son Jesus Christ to be the way, the truth, and the life; that, following the steps of thy holy Apostles, Saint Philip and Saint James, we may stedfastly walk in the way that leadeth to eternal life; through the same thy Son Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Philip and Saint James' ]
  },
  {
    title: 'Collect for Saint Barnabas the Apostle (BCP)',
    content: 'O Lord God Almighty, who didst endue thy holy Apostle Barnabas with singular gifts of the Holy Ghost: Leave us not, we beseech thee, destitute of thy manifold gifts, nor yet of grace to use them alway to thy honour and glory; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Barnabas the Apostle' ]
  },
  {
    title: "Collect for Saint John Baptist's Day (BCP)",
    content: "Almighty God, by whose providence thy servant John Baptist was wonderfully born, and sent to prepare the way of thy Son our Saviour, by preaching of repentance: Make us so to follow his doctrine and holy life, that we may truly repent according to his preaching; and after his example constantly speak the truth, boldly rebuke vice, and patiently suffer for the truth's sake; through Jesus Christ our Lord. Amen.",
    tags: [ 'BCP', 'Collect', 'Saint John Baptist' ]
  },
  {
    title: "Collect for Saint Peter's Day (BCP)",
    content: 'O Almighty God, who by thy Son Jesus Christ didst give to thy Apostle Saint Peter many excellent gifts, and commandedst him earnestly to feed thy flock: Make, we beseech thee, all Bishops and Pastors diligently to preach thy holy Word, and the people obediently to follow the same, that they may receive the crown of everlasting glory; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Peter' ]
  },
  {
    title: 'Collect for Saint James the Apostle (BCP)',
    content: 'Grant, O merciful God, that as thine holy Apostle Saint James, leaving his father and all that he had, without delay was obedient unto the calling of thy Son Jesus Christ, and followed him; so we, forsaking all worldly and carnal affections, may be evermore ready to follow thy holy commandments; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint James the Apostle' ]
  },
  {
    title: 'Collect for Saint Bartholemew the Apostle (BCP)',
    content: 'O Almighty and everlasting God, who didst give to thine Apostle Bartholomew grace truly to believe and to preach thy Word: Grant, we beseech thee, unto thy Church, to love that Word which he believed, and both to preach and receive the same; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Bartholemew the Apostle' ]
  },
  {
    title: 'Collect for Saint Matthew the Apostle (BCP)',
    content: 'O Almighty God, who by thy blessed Son didst call Matthew from the receipt of custom to be an Apostle and Evangelist: Grant us grace to forsake all covetous desires and inordinate love of riches, and to follow the same thy Son Jesus Christ, who liveth and reigneth with thee and the Holy Ghost, one God, world without end. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Matthew the Apostle' ]
  },
  {
    title: 'Collect for Saint Michael and All Angels (BCP)',
    content: 'O everlasting God, who hast ordained and constituted the services of Angels and men in a wonderful order: Mercifully grant that, as thy holy Angels alway do thee service in heaven, so by thy appointment they may succour and defend us on earth; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Michael and All Angels' ]
  },
  {
    title: 'Collect for Saint Luke the Evangelist (BCP)',
    content: 'Almighty God, who calledst Luke the Physician, whose praise is in the Gospel, to be an Evangelist, and Physician of the soul: May it please thee that, by the wholesome medicines of the doctrine delivered by him, all the diseases of our souls may be healed; through the merits of thy Son Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Luke the Evangelist' ]
  },
  {
    title: 'Collect for Saint Simon and Saint Jude, Apostles (BCP)',
    content: 'O Almighty God, who hast built thy Church upon the foundation of the Apostles and Prophets, Jesus Christ himself being the head corner-stone: Grant us so to be joined together in unity of spirit by their doctrine, that we may be made an holy temple acceptable unto thee; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', 'Saint Simon and Saint Jude, Apostles' ]
  },
  {
    title: "Collect for All Saints' Day (BCP)",
    content: 'O Almighty God, who hast knit together thine elect in one communion and fellowship, in the mystical body of thy Son Christ our Lord: Grant us grace so to follow thy blessed Saints in all virtuous and godly living, that we may come to those unspeakable joys, which thou hast prepared for them that unfeignedly love thee; through Jesus Christ our Lord. Amen.',
    tags: [ 'BCP', 'Collect', "All Saints' Day" ]
  },
];

for (let i = 0; i < entries.length; i++) {
  entries[i].id = i;
}

export default entries;
