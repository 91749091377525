import React from 'react';
import { Link } from "react-router-dom";

import './resultItem.scss';

export function ResultItem(props) {
  return (
    <Link to={`/i/${props.data.id}`}>
      <div className="result">
        <span className="header">{props.data.title}</span>
        <span className="content">{props.data.content}</span>
      </div>
    </Link>
  );
}
