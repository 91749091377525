import React, { useRef, useState } from 'react';

import { FilterOptions } from "./FilterOptions.js";

import './filterInput.scss';

export function FilterInput({
  setInputVal,
  filterOptions,
  currentFilters,
  addFilter,
  inputVal,
  removeFilter,
}) {
  const [ highlight, setHighlight ] = useState(0);

  inputVal = inputVal.trimLeft();

  const validOptions = filterOptions.filter(option => {
    if (!inputVal) return false;

    const filter = new RegExp(`\\b${inputVal}`, "i");

    return filter.test(option.name) &&                                                                // only if the option contains the search term
           !currentFilters.filter(currentFilter => currentFilter.name === option.name).length         // and only if the option is not currently selected
  }).slice(0, 20);

  function handleChange(event) {
    setInputVal(event.target.value);

    setHighlight(0);
  }

  function handleKeyDown(event) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();

        setHighlight(prevHighlight => {
          if (prevHighlight === validOptions.length - 1) {
            return prevHighlight;
          }

          return prevHighlight + 1;
        });
        break;
      case 'ArrowUp':
        event.preventDefault();
        setHighlight(prevHighlight => prevHighlight && (prevHighlight - 1));
        break;
      case 'Enter':
        event.preventDefault();
        if (!validOptions.length) {
          break;
        }
        addFilterAndFocus(validOptions[highlight]);
        break;
      case 'Backspace':
        if (!inputVal) { // if the input is currently empty
          removeFilter(currentFilters.at(-1));
        }
        break;
      default:
        break;
    }
  }

  const inputEl = useRef(null);

  const addFilterAndFocus = (...args) => {
    addFilter(...args);
    inputEl.current.focus();
  };

  return <div className="inputContainer">
    <input
      type="text"
      placeholder="Filter (e.g. celebration name, type of text)"
      value={inputVal}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      ref={inputEl}
    />
    <FilterOptions validOptions={validOptions} addFilter={addFilterAndFocus} currentFilters={currentFilters} highlight={highlight} />
  </div>
}

