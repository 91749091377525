import React from 'react';

import { ResultItem } from "./ResultItem.js";

import './resultContainer.scss';

function filtersToComplexFilter(filters) {
  const complexFilter = {
    celebrations: [],
    types: [],
    sources: [],
  };

  for (let filter of filters) {
    complexFilter[filter.type].push(filter);
  }

  return complexFilter;
}

function itemPassesComplexFilter(item, complexFilter) {
  const count = Object.values(complexFilter).map(filterList => {
    if (filterList.length === 0) return true;

    return filterList.reduce((acc, filter) => {
      if (item.tags.includes(filter.name)) {
        acc = true;
      }
      return acc;
    }, false);
  }).reduce((acc, pass) => (pass ? acc + 1 : acc), 0);

  return count === 3;
}

export function ResultContainer({ filters, content }) {
  const complexFilter = filtersToComplexFilter(filters);

  const filteredOptions = content.filter(item => {
    return itemPassesComplexFilter(item, complexFilter);
  });

  return (
    <div className="result_container">
      {filteredOptions.map(item => (<ResultItem key={item.title} data={item}/>))}
    </div>
  )
}
