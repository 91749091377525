import React from 'react';

export default function Split({ content }) {
  content = content.split("\n\n");

  return content.map((item, idx) => {
    return <p key={`para-${idx}`}>
     { item }
    </p>
  });
}
