import React from 'react';
import {
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Split from './Split.js';
import { getFilterByName } from '../data/filters.js';

import 'react-toastify/dist/ReactToastify.css';
import './displayApp.scss';

export function DisplayApp({
  data
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const displayItem = data[id];

  const handleBack = event => {
    event.preventDefault();
    navigate(-1);
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(displayItem.content);
    toast("✨ Copied ✨");
  };

  return (
    <div className="showItem">
      <h1><Link to="/" onClick={handleBack}>🡰</Link> {displayItem.title}</h1>
      <div className="content">
        <div className="body">
          <Split content={displayItem.content} />
        </div>
        <div className="info">
          <button onClick={handleCopy}> Copy</button>
          <div className="tags">
            {displayItem.tags.map(tag =>
              <Link to={`/?q=${tag}`} key={tag}>
                <div className={`tag ${getFilterByName(tag).type}`}>{tag}</div>
              </Link>
            )}
            <ToastContainer position="bottom-right"/>
          </div>
        </div>
      </div>
    </div>
  );
}
