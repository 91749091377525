import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FilterContainer } from "./FilterContainer.js";
import { ResultContainer } from "./ResultContainer.js";

import data from '../data/entries.js';
import filters, { getFilterByName } from '../data/filters.js';

export function SearchApp() {
  const [content] = useState(data);
  const navigate = useNavigate();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const chosenFilters = [];

  if (searchParams.get('q')) {
    const newFilters = searchParams
                                   .get('q')
                                   .split('|')
                                   .filter(name => getFilterByName(name))
                                   .map(name => getFilterByName(name));
    chosenFilters.push(...newFilters);
  }

  function goToNewFilterList(filterList) {
    if (filterList.length === 0) {
      navigate('/');
      return;
    }

    const newFilterString = filterList.map(item => item.name).join('|');
    navigate(`/?q=${newFilterString}`);
  }

  const removeFilter = filter => {
    if (!chosenFilters.includes(filter)) return;

    const newFilterList = [...chosenFilters];
    newFilterList.splice(newFilterList.indexOf(filter), 1);

    goToNewFilterList(newFilterList);
  };

  const addFilter = filter => {
    filter = getFilterByName(filter.name);

    if (chosenFilters.includes(filter)) return;

    const newFilterList = [...chosenFilters, filter];

    goToNewFilterList(newFilterList);
  }

  const filterOptions = Object.values(filters).reduce((acc, value) => [...acc, ...value], []);

  return (
    <>
      <FilterContainer filters={chosenFilters} removeFilter={removeFilter} filterOptions={filterOptions} addFilter={addFilter} getFilterByName={getFilterByName} />
      <ResultContainer content={content} filters={chosenFilters} />
    </>
  );
}
