import React from 'react';
import ReactDOM from 'react-dom';

import './global.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { SearchApp } from "./components/SearchApp.js";
import { DisplayApp } from "./components/DisplayApp.js";

import data from './data/entries.js';

// ========================================

ReactDOM.render(
  <React.StrictMode>
    <div className="main_container">
      <Router>
        <Routes>
          <Route index element={<SearchApp/>} />
          <Route path="/i/:id" element={<DisplayApp data={data} />} />
        </Routes>
      </Router>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

