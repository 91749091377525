const filters = {
  celebrations: [
    {
      name: "First Sunday in Advent"
    },
    {
      name: "Second Sunday in Advent"
    },
    {
      name: "Third Sunday in Advent"
    },
    {
      name: "Fourth Sunday in Advent"
    },
    {
      name: "Christmas Day"
    },
    {
      name: "First Sunday after Christmas Day"
    },
    {
      name: "Circumcision of Christ"
    },
    {
      name: "Second Sunday after Christmas Day"
    },
    {
      name: "The Epiphany"
    },
    {
      name: "First Sunday after the Epiphany"
    },
    {
      name: "Second Sunday after the Epiphany"
    },
    {
      name: "Third Sunday after the Epiphany"
    },
    {
      name: "Fourth Sunday after the Epiphany"
    },
    {
      name: "Fifth Sunday after the Epiphany"
    },
    {
      name: "Sixth Sunday after the Epiphany"
    },
    {
      name: "Septuagesima"
    },
    {
      name: "Sexagesima"
    },
    {
      name: "Quinquagesima"
    },
    {
      name: "Ash Wednesday"
    },
    {
      name: "First Sunday in Lent"
    },
    {
      name: "Second Sunday in Lent"
    },
    {
      name: "Third Sunday in Lent"
    },
    {
      name: "Fourth Sunday in Lent"
    },
    {
      name: "Fifth Sunday in Lent"
    },
    {
      name: "Sunday Next before Easter"
    },
    {
      name: "Good Friday"
    },
    {
      name: "Easter Even"
    },
    {
      name: "Easter Day"
    },
    {
      name: "First Sunday after Easter"
    },
    {
      name: "Second Sunday after Easter"
    },
    {
      name: "Third Sunday after Easter"
    },
    {
      name: "Fourth Sunday after Easter"
    },
    {
      name: "Fifth Sunday after Easter"
    },
    {
      name: "Ascension Day"
    },
    {
      name: "Sunday after Ascension Day"
    },
    {
      name: "Whitsunday"
    },
    {
      name: "Trinity Sunday"
    },
    {
      name: "First Sunday after Trinity"
    },
    {
      name: "Second Sunday after Trinity"
    },
    { name: 'Third Sunday after Trinity' },
    { name: 'Fourth Sunday after Trinity' },
    { name: 'Fifth Sunday after Trinity' },
    { name: 'Sixth Sunday after Trinity' },
    { name: 'Seventh Sunday after Trinity' },
    { name: 'Eighth Sunday after Trinity' },
    { name: 'Ninth Sunday after Trinity' },
    { name: 'Tenth Sunday after Trinity' },
    { name: 'Eleventh Sunday after Trinity' },
    { name: 'Twelfth Sunday after Trinity' },
    { name: 'Thirteenth Sunday after Trinity' },
    { name: 'Fourteenth Sunday after Trinity' },
    { name: 'Fifteenth Sunday after Trinity' },
    { name: 'Sixteenth Sunday after Trinity' },
    { name: 'Seventeenth Sunday after Trinity' },
    { name: 'Eighteenth Sunday after Trinity' },
    { name: 'Nineteenth Sunday after Trinity' },
    { name: 'Twentieth Sunday after Trinity' },
    { name: 'Twenty-First Sunday after Trinity' },
    { name: 'Twenty-Second Sunday after Trinity' },
    { name: 'Twenty-Third Sunday after Trinity' },
    { name: 'Twenty-Fourth Sunday after Trinity' },
    { name: 'Twenty-Fifth Sunday after Trinity' },
    { name: 'Saint Andrew' },
    { name: 'Saint Thomas the Apostle' },
    { name: 'Conversion of Saint Paul' },
    { name: 'Purification of Saint Mary the Virgin' },
    { name: 'Saint Matthias' },
    { name: 'Annunciation of the Blessed Virgin Mary' },
    { name: 'Saint Mark' },
    { name: 'Saint Philip and Saint James' },
    { name: 'Saint Barnabas the Apostle' },
    { name: 'Saint John Baptist' },
    { name: 'Saint Peter' },
    { name: 'Saint James the Apostle' },
    { name: 'Saint Bartholemew the Apostle' },
    { name: 'Saint Matthew the Apostle' },
    { name: 'Saint Michael and All Angels' },
    { name: 'Saint Luke the Evangelist' },
    { name: 'Saint Simon and Saint Jude, Apostles' },
    { name: "All Saints' Day" },
  ],
  sources: [
    {
      name: "BCP"
    },
    {
      name: "Common Worship"
    }
  ],
  types: [
    {
      name: "Collect"
    },
    {
      name: "Post-Communion"
    }
  ]
};

export default filters;

export function getFilterByName(name) {
  const listByName = Object.entries(filters).reduce((acc, [type, list]) => {
    acc.push(...list.reduce((acc, filter) => {
      if (filter.name === name) {
        acc.push({
          ...filter,
          type
        });
      }

      return acc;
    }, []));
    return acc;
  }, []);

  return listByName[0];
}
